import get from "lodash/get";
import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const TERRITORIES_LIST = createActionTypesOf("TERRITORIES_LIST");

const requestTerritoriesListAction = () => ({
  type: TERRITORIES_LIST.REQUEST,
});

const requestTerritoriesListSuccessAction = (data) => ({
  type: TERRITORIES_LIST.SUCCESS,
  payload: {
    territories: get(data, ["territories"], []),
    regions: get(data, ["regions"], []),
  },
});

const requestTerritoriesListErrorAction = (error) => ({
  type: TERRITORIES_LIST.ERROR,
  payload: { error },
});

export const getTerritoriesListAction = () => ({
  type: "TERRITORIES_LIST",
});

function* getTerritoriesList() {
  try {
    let territoriesData = [];
    const queryParams = `?regions=*&territories=*`;

    yield put(requestTerritoriesListAction());
    yield call(() =>
      Service.get(
        "gw",
        config.apiPath.gw.getTerritoryList + queryParams,
        function (status, result) {
          territoriesData = result.data;
        }
      )
    );
    yield put(requestTerritoriesListSuccessAction(territoriesData));
  } catch (error) {
    yield put(requestTerritoriesListErrorAction(error));
  }
}

export function* watchGetTerritoriesList() {
  yield takeEvery("TERRITORIES_LIST", getTerritoriesList);
}
