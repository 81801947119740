import { TERRITORIES_LIST } from "../actions/getTerritoriesActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataState: DATA_STATES.NOT_REQUESTED,
  territories: [],
  regions: [],
  error: [],
};

const getTerritoriesListReducer = createReducer(initialState, {
  [TERRITORIES_LIST.REQUEST]: (state) => ({
    ...state,
    dataState: DATA_STATES.REQUEST,
  }),
  [TERRITORIES_LIST.SUCCESS]: (
    state,
    { payload: { territories, regions } }
  ) => ({
    ...state,
    dataState: DATA_STATES.READY,
    territories,
    regions,
  }),
  [TERRITORIES_LIST.ERROR]: (state, { payload: { error } }) => ({
    ...state,
    dataState: DATA_STATES.FAILURE,
    error,
  }),
});

export default getTerritoriesListReducer;
