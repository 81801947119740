import { put, takeEvery } from "redux-saga/effects";

import { createActionTypesOf } from "../utils/helpers";

export const HORIZON_SCANNING_SET_TOPIC_FILTER = createActionTypesOf(
  "HORIZON_SCANNING_SET_TOPIC_FILTER"
);
export const HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY = createActionTypesOf(
  "HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY"
);
export const HORIZON_SCANNING_TOPIC_FILTER_STATE = createActionTypesOf(
  "HORIZON_SCANNING_TOPIC_FILTER_STATE"
);

const requestSetTopicFilterItemAction = (payload) => ({
  type: HORIZON_SCANNING_SET_TOPIC_FILTER.REQUEST,
  payload,
});

const requestSetSuccessTopicFilterItemAction = () => ({
  type: HORIZON_SCANNING_SET_TOPIC_FILTER.SUCCESS,
});

function* setTopicFilterItem(action) {
  yield put(requestSetTopicFilterItemAction(action.payload));
  yield put(requestSetSuccessTopicFilterItemAction());
}

export const setTopicFilterItemAction = (payload) => ({
  type: "HORIZON_SCANNING_TOPIC_SET_FILTER_ITEM",
  payload,
});

export function* watchSetHSTopicFilterItem() {
  yield takeEvery("HORIZON_SCANNING_TOPIC_SET_FILTER_ITEM", setTopicFilterItem);
}

const requestSetTopicFilterReadyAction = () => ({
  type: HORIZON_SCANNING_TOPIC_FILTER_STATE.REQUEST,
});

function* setTopicFilterReady() {
  yield put(requestSetTopicFilterReadyAction());
}

export const setTopicFilterReadyAction = () => ({
  type: "HORIZON_SCANNING_TOPIC_SET_FILTER_READY",
});

export function* watchSetHSTopicFilterReady() {
  yield takeEvery(
    "HORIZON_SCANNING_TOPIC_SET_FILTER_READY",
    setTopicFilterReady
  );
}

const requestCancelTopicFilterReadyAction = () => ({
  type: HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY.REQUEST,
});

function* cancelTopicFilterReady() {
  yield put(requestCancelTopicFilterReadyAction());
}

export const cancelTopicFilterReadyAction = () => ({
  type: "HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY",
});

export function* watchCancelHSTopicFilterReady() {
  yield takeEvery(
    "HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY",
    cancelTopicFilterReady
  );
}
