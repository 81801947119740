import { HORIZON_SCANNING_TOPIC_INSIGHTS } from "../actions/horizonScanningTopicInsightsActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataStateTopicInsightsData: DATA_STATES.NOT_REQUESTED,
  topicInsightsData: [],
  errorTopicInsightsData: null,
};

const horizonScanningTopicinsightsReducer = createReducer(initialState, {
  [HORIZON_SCANNING_TOPIC_INSIGHTS.REQUEST]: (state) => ({
    ...state,
    dataStateTopicInsightsData: DATA_STATES.REQUEST,
  }),
  [HORIZON_SCANNING_TOPIC_INSIGHTS.SUCCESS]: (
    state,
    { payload: { data } }
  ) => ({
    ...state,
    dataStateTopicInsightsData: DATA_STATES.READY,
    topicInsightsData: data,
  }),
  [HORIZON_SCANNING_TOPIC_INSIGHTS.ERROR]: (
    state,
    { payload: { error, data } }
  ) => ({
    ...state,
    dataStateTopicInsightsData: DATA_STATES.FAILURE,
    errorTopicInsightsData: error,
    topicInsightsData: data,
  }),
});

export default horizonScanningTopicinsightsReducer;
