import {
  HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES,
  HORIZON_SCANNING_TOPIC_ARTICLES,
} from "../actions/horizonScanningTopicArticlesActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataStateTopicArticlesData: DATA_STATES.NOT_REQUESTED,
  topicArticlesData: [],
  errorTopicArticlesData: null,
};

const horizonScanningTopicArticlesReducer = createReducer(initialState, {
  [HORIZON_SCANNING_TOPIC_ARTICLES.REQUEST]: (state) => ({
    ...state,
    dataStateTopicArticlesData: DATA_STATES.REQUEST,
  }),
  [HORIZON_SCANNING_TOPIC_ARTICLES.SUCCESS]: (
    state,
    { payload: { data } }
  ) => ({
    ...state,
    dataStateTopicArticlesData: DATA_STATES.READY,
    topicArticlesData: data,
  }),
  [HORIZON_SCANNING_TOPIC_ARTICLES.ERROR]: (
    state,
    { payload: { error, data } }
  ) => ({
    ...state,
    dataStateTopicArticlesData: DATA_STATES.FAILURE,
    errorTopicArticlesData: error,
    topicArticlesData: data,
  }),
  [HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES.REQUEST]: (state) => ({
    ...state,
    ...initialState,
  }),
});

export default horizonScanningTopicArticlesReducer;
