import axios from "axios";

import * as util from "../../utils/utils";

class Service {
  constructor() {
    const service = axios.create();
    const serviceMultipart = axios.create({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const serviceSync = axios.create();

    this.service = service;
    this.serviceMultipart = serviceMultipart;
    this.serviceSync = serviceSync;
  }

  handleType = (type, path) => {
    if (type === "gw") {
      const apiGwUrl = util.getApiGatewayUrl() || "";
      return apiGwUrl + path;
    }
    if (type === "ulw") {
      return util.getUploadedGatewayUrl() + path;
    }
  };

  redirectTo = (document, path) => {
    document.location = path;
  };

  get = (type, path, callback, params) =>
    this.service
      .get(this.handleType(type, path), params || {})
      .then(function (response) {
        callback(response.status, response.data);
      });

  getAsync = (type, path, params) =>
    this.service.get(this.handleType(type, path), params || {});

  getBlob = (type, path, callback) => {
    const self = this;
    return self.service
      .request({
        method: "GET",
        url: self.handleType(type, path),
        responseType: "blob",
      })
      .then(function (response) {
        callback(response.status, response.data);
      });
  };

  getBlobAsync = (type, path) => {
    return this.service.request({
      method: "GET",
      url: this.handleType(type, path),
      responseType: "blob",
    });
  };

  patch = (type, path, payload, callback) => {
    const self = this;
    return self.service
      .request({
        method: "PATCH",
        url: self.handleType(type, path),
        responseType: "json",
        data: payload,
      })
      .then(function (response) {
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });
  };

  post = (type, path, payload, callback) =>
    this.service
      .request({
        method: "POST",
        url: this.handleType(type, path),
        responseType: "json",
        data: payload,
      })
      .then(function (response) {
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response.status,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });

  postAsync = (type, path, payload) =>
    this.service.request({
      method: "POST",
      url: this.handleType(type, path),
      responseType: "json",
      data: payload,
    });

  delete = (type, path, callback) =>
    this.service
      .request({
        method: "DELETE",
        url: this.handleType(type, path),
        responseType: "json",
      })
      .then(function (response) {
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });

  postBlob = (type, path, payload, callback) =>
    this.service
      .request({
        method: "POST",
        url: this.handleType(type, path),
        responseType: "blob",
        data: payload,
      })
      .then(function (response) {
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });

  postBlobAsync = (type, path, payload) =>
    this.service.request({
      method: "POST",
      url: this.handleType(type, path),
      responseType: "blob",
      data: payload,
    });

  postWithMultipartAsync = (type, path, payload) =>
    this.serviceMultipart.request({
      method: "POST",
      url: this.handleType(type, path),
      responseType: "json",
      data: payload,
    });

  postWithMuiltipart = (type, path, payload, callback) =>
    this.serviceMultipart
      .request({
        method: "POST",
        url: this.handleType(type, path),
        responseType: "json",
        data: payload,
      })
      .then(function (response) {
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });

  logout = (type, path, payload, callback) => {
    const self = this;
    return self.service
      .request({
        method: "POST",
        url: this.handleType(type, path),
        responseType: "json",
        data: payload,
      })
      .then(function (response) {
        util.setCookieForAPP("token", null);
        util.setCookieForAPP("userName", null);
        util.setCookieForAPP("userId", null);
        util.setCookieForAPP("userFullName", null);
        util.setCookieForAPP("loggedIn", false);
        self.redirectTo(document, "/");
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });
  };

  login = (type, path, payload, callback) => {
    const self = this;
    return self.service
      .request({
        method: "POST",
        url: this.handleType(type, path),
        responseType: "json",
        data: payload,
      })
      .then(function (response) {
        util.setCookieForAPP("token", response.data.data.token);
        util.setCookieForAPP("userName", response.data.data.user.username);
        util.setCookieForAPP("userId", response.data.data.user.id);
        util.setCookieForAPP("userFullName", response.data.data.user.name);
        util.setCookieForAPP("loggedIn", true);
        util.setCookieForAPP("userrole", response.data.data.user.userrole);
        util.setCookieForAPP("assignPage", "/dashboard/home");
        util.setCookieForAPP(
          "user",
          JSON.stringify({
            token: response.data.data.token,
            id: response.data.data.user.id,
            role: response.data.data.user.userrole,
            name: response.data.data.user.username,
          })
        );
        callback(response.status, response.data);
      })
      .catch(function (error) {
        callback(
          error.response,
          error.response && error.response.data
            ? error.response.data
            : error.response
        );
      });
  };

  getSync = async (type, path, params) => {
    const self = this;
    return await self.serviceSync.get(
      this.handleType(type, path),
      params || {}
    );
  };

  postSync = async (type, path, payload) => {
    const self = this;
    return await self.serviceSync.request({
      method: "POST",
      url: this.handleType(type, path),
      responseType: "json",
      data: payload,
    });
  };
}

export default new Service();
