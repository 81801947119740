import { ThemeOptions } from "@mui/material";

import { borderTypes } from "./borderTypes";
import { colors } from "./colors";
import { textStyles } from "./typography";

function important<T>(value: T): T {
  return (value + " !important") as any;
}

export const components: ThemeOptions["components"] = {
  MuiPaper: {
    styleOverrides: {
      root: {
        "&.MuiPaper-elevation24": {
          boxShadow:
            "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
        },
        "&.MuiPaper-rounded": {
          borderRadius: "0.5rem",
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: borderTypes.rounded,
        borderWidth: "1px",
        borderColor: theme.palette.primary.states.outlinedBorder,
        padding: "0.25rem 0.75rem",
        "&:hover": {
          backgroundColor: theme.palette.background.paper,
        },
        maxHeight: "1.5rem",
        maxWidth: "19rem",
      }),
      label: ({ theme }) => ({
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: textStyles.sizes.small,
        lineHeight: textStyles.lineHeight.small,
        padding: 0,
      }),
      deletable: {
        paddingRight: "0.25rem",
      },
      deleteIcon: {
        margin: 0,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        border: important("none"),
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: "none",
        "& .MuiOutlinedInput-root": {
          borderRadius: important("0.5rem"),
          padding: "0.5rem 0.75rem",
          rowGap: important(0),
          columnGap: important(0),
        },
        "& .MuiOutlinedInput-input": {
          color: theme.palette.text.primary,
        },
        "& .MuiAutocomplete-tag": {
          margin: important("4px 4px 2px 0"),
          maxWidth: "50%",
          maxHeight: "1.4375rem",

          "&.MuiChip-colorError": {
            border: `1px solid ${theme.palette.error.main}`,
            "& .MuiChip-label": {
              color: theme.palette.error.main,
            },
            "& .MuiChip-deleteIcon": {
              color: theme.palette.error.main,
            },
          },
        },
        "& .MuiAutocomplete-input": {
          padding: important("4px 4px 3px 6px"),
        },
        "& input": {
          border: important("none"),
        },
        "& .MuiChip-deleteIcon": {
          padding: important("0.25rem"),
        },
      }),
      option: ({ theme }) => ({
        fontSize: "0.875rem",
        color: theme.palette.text.primary,
        padding: important("0.75rem 1rem"),
        "&.subgroup-title": {
          opacity: important(1),
        },
        "&.subgroup-item": {
          padding: important("0.75rem 1rem 0.75rem 2.25rem"),
        },
        '&[aria-selected="true"]': {
          background: important("rgba(242, 246, 253, 1)"),
        },
        "&:hover": {
          background: important("rgba(242, 246, 253, 0.5)"),
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: ({ theme }) => ({
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: textStyles.sizes.large,
      }),
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minHeight: "2.875rem",
        textTransform: "none",
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        letterSpacing: "normal",
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        "& > .MuiMenu-paper.MuiPaper-root": {
          // max width of Select options
          maxWidth: "24.625rem",
        },
      },
      list: {
        padding: 0,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.grey[300],
        borderColor: "rgba(0, 0, 0, 0)",
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        margin: 0,
        borderRadius: important("8px"),
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        "& >:not(:first-of-type)": {
          marginLeft: "0.75rem",
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: important(0),
      },
    },
  },
  MuiAlert: {
    defaultProps: {
      sx: {
        width: "100%",
      },
    },
    styleOverrides: {
      root: () => ({
        columnGap: "1rem",
        padding: "0.5rem 1rem",
        ".MuiAlert-message": {
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: 0,
        },
      }),
      action: {
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: 0,
        padding: 0,
      },
      icon: {
        marginTop: "auto",
        marginBottom: "auto",
        marginRight: 0,
      },
      standard: {
        borderRadius: "0.5rem",
      },
      filled: {
        borderRadius: "0.5rem",
        border: "1px solid",
        svg: {
          path: {
            fill: colors.neutrals.white,
          },
        },
      },
      outlined: {
        borderRadius: "0.5rem",
        border: "1px solid",
      },
      // filled
      filledInfo: ({ theme }) => ({
        backgroundColor: important(theme.palette.info.main),
        svg: {
          path: {
            fill: important(theme.palette.info.contrastText),
          },
        },
      }),
      filledSuccess: ({ theme }) => ({
        backgroundColor: important(theme.palette.success.main),
        svg: {
          path: {
            fill: important(theme.palette.success.contrastText),
          },
        },
      }),
      filledWarning: ({ theme }) => ({
        backgroundColor: important(theme.palette.warning.main),
        svg: {
          path: {
            fill: important(theme.palette.warning.contrastText),
          },
        },
      }),
      filledError: ({ theme }) => ({
        backgroundColor: important(theme.palette.error.main),
        svg: {
          path: {
            fill: important(theme.palette.error.contrastText),
          },
        },
      }),
      // standard
      standardInfo: ({ theme }) => ({
        backgroundColor: important(colors.semantic.info[10]),
        svg: {
          path: {
            fill: theme.palette.info.main,
          },
        },
      }),
      standardSuccess: ({ theme }) => ({
        backgroundColor: colors.semantic.success[10],
        svg: {
          path: {
            fill: theme.palette.success.main,
          },
        },
      }),
      standardWarning: ({ theme }) => ({
        backgroundColor: important(colors.semantic.warning[10]),
        svg: {
          path: {
            fill: theme.palette.warning.main,
          },
        },
      }),
      standardError: ({ theme }) => ({
        backgroundColor: important(colors.semantic.error[10]),
        svg: {
          path: {
            fill: theme.palette.error.main,
          },
        },
      }),
      // outlined
      outlinedInfo: ({ theme }) => ({
        borderColor: theme.palette.info.main,
        svg: {
          path: {
            fill: theme.palette.info.main,
          },
        },
      }),
      outlinedSuccess: ({ theme }) => ({
        borderColor: theme.palette.success.main,
        svg: {
          path: {
            fill: theme.palette.success.main,
          },
        },
      }),
      outlinedWarning: ({ theme }) => ({
        borderColor: theme.palette.warning.main,
        svg: {
          path: {
            fill: theme.palette.warning.main,
          },
        },
      }),
      outlinedError: ({ theme }) => ({
        borderColor: theme.palette.error.main,
        svg: {
          path: {
            fill: theme.palette.error.main,
          },
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        color: theme.palette.text.primary,
        fontSize: "0.875rem",
        marginLeft: "0.5rem",
      }),
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      text: {
        transform: "none",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        padding: "0.75rem",
      }),
      stickyHeader: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        borderTop: "none",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& > .MuiBackdrop-root": {
          backgroundColor: theme.palette.background.default,
          opacity: important(0.5), // to redefine inline styles
        },
      }),
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ".MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-completed": {
          fontWeight: theme.typography.fontWeightBold,
        },
      }),
      label: {
        fontSize: textStyles.sizes.medium,
        lineHeight: textStyles.lineHeight.small,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: "rgba(62, 66, 71, 0.90)",
        borderRadius: "0.5rem",
        fontSize: "0.75rem",
        fontWeight: 500,
        padding: "0.25rem 0.5rem",
      },
      arrow: {
        color: "rgba(62, 66, 71, 0.90)",
        width: "1rem",
      },
    },
  },
};
