export const createActionTypesOf = (actionName) => ({
  REQUEST: `${actionName}_REQUEST`,
  SUCCESS: `${actionName}_SUCCESS`,
  ERROR: `${actionName}_ERROR`,
  STATUS: `${actionName}_STATUS`,
  BEGIN: `${actionName}_BEGIN`,
  END: `${actionName}_END`,
});

const userRoles = {
  manager: "manager",
  externalExpert: "externalexpert",
  expert: "expert",
};

export const formatExpertName = (name, role) => {
  const firstName = name.substr(0, 1);
  const secondName = name.split(" ")?.[1]?.substr(0, 1) || "";
  let formatRole = "";
  switch (role) {
    case userRoles.manager:
      formatRole = "Senior Manager";
      break;
    case userRoles.externalExpert:
      formatRole = "Senior Expert";
      break;
    case userRoles.expert:
      formatRole = "Senior Reviewer";
    default:
      break;
  }
  return formatRole ? `${firstName}.${secondName}., ${formatRole}` : "";
};

export const createReducer = (initialState, reducerMap) =>
  function (state = initialState, action) {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action) : state;
  };

export const createAction = (reducer, name) => `${reducer}.${name}`;

export const isStringsSameNoRegister = (str1, str2) =>
  str1?.toLowerCase() === str2?.toLowerCase();

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
