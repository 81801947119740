export const PAGE_HAS_BEEN_FORCE_REFRESHED = "page-has-been-force-refreshed";

export enum ITEMS {
  AuthForgotPasswordEmail = "auth-forgot-password-email",
  DraftCompanyAlert = "draft-company-alert",
  SSOEmail = "sso-email",
}

export const getItem = (key: string) => {
  const item = localStorage.getItem(key);

  if (item === null || item === "null" || item === "undefined" || !item) {
    return null;
  }

  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
};

export const setItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
