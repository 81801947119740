import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const AUTHORITIES_LIST = createActionTypesOf("AUTHORITIES_LIST");

const requestAuthoritiesListAction = () => ({
  type: AUTHORITIES_LIST.REQUEST,
});

const requestAuthoritiesListSuccessAction = (data) => ({
  type: AUTHORITIES_LIST.SUCCESS,
  payload: { data },
});

const requestAuthoritiesListErrorAction = (error) => ({
  type: AUTHORITIES_LIST.ERROR,
  payload: { error },
});

function* getAuthoritiesList() {
  try {
    yield put(requestAuthoritiesListAction());
    const response = yield call(() =>
      Service.postSync("gw", config.apiPath.gw.getAuthorityList, {})
    );
    yield put(requestAuthoritiesListSuccessAction(response.data.data));
  } catch (error) {
    yield put(requestAuthoritiesListErrorAction(error));
  }
}

export function* watchGetAuthoritiesList() {
  yield takeEvery("AUTHORITIES_LIST", getAuthoritiesList);
}
