import { lazy } from "react";

import {
  getItem,
  PAGE_HAS_BEEN_FORCE_REFRESHED,
  setItem,
} from "../services/storages/localStorage";

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = getItem(
      PAGE_HAS_BEEN_FORCE_REFRESHED
    );

    try {
      const component = await componentImport();

      setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, true);

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, false);
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
