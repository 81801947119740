import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const HORIZON_SCANNING = createActionTypesOf("HORIZON_SCANNING");
export const HORIZON_SCANNING_FILTER = createActionTypesOf(
  "HORIZON_SCANNING_FILTER"
);
export const HORIZON_SCANNING_SET_TOPIC = createActionTypesOf(
  "HORIZON_SCANNING_SET_TOPIC"
);
export const HORIZON_SCANNING_FILTER_STATE = createActionTypesOf(
  "HORIZON_SCANNING_FILTER_STATE"
);

const requestHSChartsDataAction = () => ({
  type: HORIZON_SCANNING.REQUEST,
});

const requestHSChartsDataSuccessAction = (data) => ({
  type: HORIZON_SCANNING.SUCCESS,
  payload: data,
});

const requestHSChartsDataErrorAction = (error) => ({
  type: HORIZON_SCANNING.ERROR,
  payload: error,
});

function* getHSChartsData(action) {
  const {
    timeRangeFrom,
    timeRangeTo,
    dateRangeTitle,
    territories,
    authorities,
  } = action.payload;

  try {
    yield put(requestHSChartsDataAction());
    const response = yield call(() => {
      const payload = {
        filters: {
          territories,
          timeframe: dateRangeTitle,
        },
      };
      // date-from=${timeRangeFrom}&date-to=${timeRangeTo}&&authorities=${authorities.join()}`;
      return Service.postSync(
        "gw",
        `${config.apiPath.gw.getHSCharts}`,
        payload
      );
    });
    yield put(requestHSChartsDataSuccessAction(response.data));
  } catch (error) {
    yield put(requestHSChartsDataErrorAction(error));
  }
}

export const getHSChartsDataAction = (payload) => ({
  type: "HORIZON_SCANNING_CHARTS",
  payload,
});

export function* watchGetHSChartsData() {
  yield takeEvery("HORIZON_SCANNING_CHARTS", getHSChartsData);
}

const requestSetFilterItemAction = (payload) => ({
  type: HORIZON_SCANNING_FILTER.REQUEST,
  payload,
});

const requestSetSuccessFilterItemAction = () => ({
  type: HORIZON_SCANNING_FILTER.SUCCESS,
});

function* setFilterItem(action) {
  yield put(requestSetFilterItemAction(action.payload));
  yield put(requestSetSuccessFilterItemAction());
}

export const setFilterItemAction = (payload) => ({
  type: "HORIZON_SCANNING_SET_FILTER_ITEM",
  payload,
});

export function* watchSetHSFilterItem() {
  yield takeEvery("HORIZON_SCANNING_SET_FILTER_ITEM", setFilterItem);
}

const requestSetFilterReadyAction = () => ({
  type: HORIZON_SCANNING_FILTER_STATE.REQUEST,
});

function* setFilterReady() {
  yield put(requestSetFilterReadyAction());
}

export const setFilterReadyAction = () => ({
  type: "HORIZON_SCANNING_SET_FILTER_READY",
});

export function* watchSetHSFilterReady() {
  yield takeEvery("HORIZON_SCANNING_SET_FILTER_READY", setFilterReady);
}

const requestSetHSTopic = (payload) => ({
  type: HORIZON_SCANNING_SET_TOPIC.REQUEST,
  payload,
});

const responseSetHSTopic = () => ({
  type: HORIZON_SCANNING_SET_TOPIC.SUCCESS,
});

function* setHSTopic(action) {
  yield put(requestSetHSTopic(action.payload));
  yield put(responseSetHSTopic());
}

export const setHSTopicAction = (payload) => ({
  type: "HORIZON_SCANNING_SET_CHOOSED_TOPIC",
  payload,
});

export function* watchSetHSTopic() {
  yield takeEvery("HORIZON_SCANNING_SET_CHOOSED_TOPIC", setHSTopic);
}
