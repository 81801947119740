import { all } from "redux-saga/effects";

import { watchGetAuthoritiesList } from "./actions/getAuthoritiesActions";
import { watchGetHSChartsFilterMetadata } from "./actions/getHSChartsFilterMetadataActions";
import { watchGetHSTopicFilterMetadata } from "./actions/getHSTopicFilterMetadataActions";
import { watchGetTerritoriesList } from "./actions/getTerritoriesActions";
import {
  watchGetHSChartsData,
  watchSetHSFilterItem,
  watchSetHSFilterReady,
  watchSetHSTopic,
} from "./actions/horizonScanningActions";
import {
  watchSetHSChartColors,
  watchSetHSSignalsColors,
} from "./actions/horizonScanningChartColorsActions";
import {
  watchClearHSTopicArticlesData,
  watchGetHSTopicArticlesData,
} from "./actions/horizonScanningTopicArticlesActions";
import {
  watchCancelHSTopicFilterReady,
  watchSetHSTopicFilterItem,
  watchSetHSTopicFilterReady,
} from "./actions/horizonScanningTopicFilterActions";
import { watchGetHSTopicInsightsData } from "./actions/horizonScanningTopicInsightsActions";
import { watchNotification } from "./actions/notificationActions";

export default function* rootSaga() {
  yield all([
    watchGetAuthoritiesList(),

    watchGetTerritoriesList(),

    watchGetHSChartsData(),
    watchSetHSFilterItem(),
    watchSetHSFilterReady(),
    watchSetHSTopic(),
    watchGetHSChartsFilterMetadata(),
    watchSetHSChartColors(),
    watchSetHSSignalsColors(),

    watchSetHSTopicFilterItem(),
    watchSetHSTopicFilterReady(),
    watchCancelHSTopicFilterReady(),
    watchGetHSTopicInsightsData(),
    watchGetHSTopicArticlesData(),
    watchClearHSTopicArticlesData(),
    watchGetHSTopicFilterMetadata(),

    watchNotification(),
  ]);
}
