import { HORIZON_SCANNING_SET_CHART_COLOR } from "../actions/horizonScanningChartColorsActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  colors: [],
  signalsColors: {},
  dataState: DATA_STATES.NOT_REQUESTED,
  defaultColors: [
    "rgba(98, 160, 202, 1)",
    "rgba(255, 165, 86, 1)",
    "rgba(63, 168, 63, 1)",
    "rgba(169, 214, 39, 1)",
    "rgba(154, 97, 176, 1)",
    "rgba(214, 39, 40, 1)",
    "rgba(228, 211, 84, 1)",
    "rgba(43, 144, 143, 1)",
    "rgba(139, 69, 19, 1)",
    "rgba(145, 232, 225, 1)",
  ],
  resultColorstCount: 150,
};

const horizonScanningReducer = createReducer(initialState, {
  [HORIZON_SCANNING_SET_CHART_COLOR.REQUEST]: (
    state,
    { payload: { colors } }
  ) => ({
    ...state,
    colors,
    dataState: DATA_STATES.REQUEST,
  }),
  [HORIZON_SCANNING_SET_CHART_COLOR.SUCCESS]: (
    state,
    { payload: { colors, signalsColors } }
  ) => ({
    ...state,
    colors,
    signalsColors,
    dataState: DATA_STATES.READY,
  }),
});

export default horizonScanningReducer;
