export const colors = {
  brand: {
    oxfordBlue: {
      5: "#FAFCFE",
      10: "#E4EEFB",
      20: "#BDD6F5",
      30: "#90BAEE",
      40: "#649FE8",
      50: "#3883E1",
      60: "#1E6AC7",
      70: "#17529B",
      80: "#113B6F",
      90: "#0A2342",
    },
    crayolaPink: {
      5: "#FEF9FA",
      10: "#FCE3E7",
      20: "#F7ACB7",
      30: "#F17487",
      40: "#ED4D65",
      50: "#DE354F",
      60: "#B81E35",
      70: "#91182A",
      80: "#65101D",
      90: "#35090F",
    },
    tiffany: {
      5: "#F6FDFE",
      10: "#E5FAFB",
      20: "#C1EFF0",
      30: "#98E4E7",
      40: "#6FD9DD",
      50: "#46CED3",
      60: "#2CB4BA",
      70: "#228C90",
      80: "#186467",
      90: "#0F3C3E",
    },
    lavender: {
      5: "#F6F7FE",
      10: "#E5E8FB",
      20: "#C5CAEC",
      30: "#A5ACD9",
      40: "#8A91C1",
      50: "#777DA7",
      60: "#5A608B",
      70: "#464B6C",
      80: "#32364D",
      90: "#1E202E",
    },
    purple: {
      5: "#FBFAFE",
      10: "#EAE8FC",
      20: "#C6C2EF",
      30: "#A6A0DF",
      40: "#918AD7",
      50: "#5E54C5",
      60: "#453AAB",
      70: "#352D85",
      80: "#26205F",
      90: "#171339",
    },
    mint: {
      5: "#F9FEFD",
      10: "#E3FCF4",
      20: "#BFF2E1",
      30: "#9BE4CA",
      40: "#7CD0B3",
      50: "#50AF8E",
      60: "#439377",
      70: "#387B63",
      80: "#285847",
      90: "#18352B",
    },
    sunglow: {
      5: "#FFFDF6",
      10: "#FFF9E5",
      20: "#FFEDB2",
      30: "#FFE180",
      40: "#FFD23F",
      50: "#FFC60A",
      60: "#E5B000",
      70: "#B28900",
      80: "#806200",
      90: "#4D3B00",
    },
  },
  neutrals: {
    black: "#000000",
    white: "#FFFFFF",
    grey: {
      10: "#FAFAFA",
      20: "#EFF0F0",
      30: "#DFE0E2",
      40: "#BFC2C4",
      50: "#93989F",
      60: "#6C727A",
      70: "#3E4247",
      80: "#1B1E22",
    },
  },
  semantic: {
    info: {
      10: "#E0F3FF",
      20: "#99D6FF",
      30: "#1370D4",
      40: "#044D9C",
    },
    success: {
      10: "#E5FBEC",
      20: "#A7F1C0",
      30: "#228543",
      40: "#094F20",
    },
    warning: {
      10: "#FFEEE1",
      20: "#FEC59A",
      30: "#F06E0C",
      40: "#AD4B00",
    },
    error: {
      10: "#FFEEED",
      20: "#FCBDB8",
      30: "#BA2A20",
      40: "#A1241B",
    },
  },
};
