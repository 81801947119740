import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const HORIZON_SCANNING_TOPIC_INSIGHTS = createActionTypesOf(
  "HORIZON_SCANNING_TOPIC_INSIGHTS"
);

const requestHSTopicInsightsDataAction = () => ({
  type: HORIZON_SCANNING_TOPIC_INSIGHTS.REQUEST,
});

const requestHSTopicInsightsDataSuccessAction = (data) => ({
  type: HORIZON_SCANNING_TOPIC_INSIGHTS.SUCCESS,
  payload: { data },
});

const requestHSTopicInsightsDataErrorAction = (error) => ({
  type: HORIZON_SCANNING_TOPIC_INSIGHTS.ERROR,
  payload: { error },
});

function* getHSTopicInsightsData(action) {
  const { dateRangeTitle, regions, authorities, topicId, sortBy, isUnread } =
    action.payload;

  try {
    yield put(requestHSTopicInsightsDataAction());
    const response = yield call(() => {
      const payload = {
        filters: {
          regions,
          timeframe: dateRangeTitle,
        },
        id: topicId,
      };
      return Service.postSync("gw", `${config.apiPath.gw.getHSTopic}`, payload);
    });
    yield put(requestHSTopicInsightsDataSuccessAction(response.data));
  } catch (error) {
    yield put(requestHSTopicInsightsDataErrorAction(error));
  }
}

const getHSTopicInsightsDataAction = (payload) => ({
  type: "HORIZON_SCANNING_TOPIC_INSIGHTS",
  payload,
});

export function* watchGetHSTopicInsightsData() {
  yield takeEvery("HORIZON_SCANNING_TOPIC_INSIGHTS", getHSTopicInsightsData);
}
