import { put, takeEvery } from "redux-saga/effects";

import { createActionTypesOf } from "../utils/helpers";

export const HORIZON_SCANNING_SET_CHART_COLOR = createActionTypesOf(
  "HORIZON_SCANNING_SET_CHART_COLOR"
);

const requestSetHSChartColorsAction = (payload) => ({
  type: HORIZON_SCANNING_SET_CHART_COLOR.REQUEST,
  payload,
});

const requestSetHSSignalsColorsAction = (payload) => ({
  type: HORIZON_SCANNING_SET_CHART_COLOR.SUCCESS,
  payload,
});

function* setHSChartColors(action) {
  yield put(requestSetHSChartColorsAction(action.payload));
}

export const setHSChartColorsAction = (payload) => ({
  type: "HORIZON_SCANNING_SET_CHART_COLORS",
  payload,
});

export function* watchSetHSChartColors() {
  yield takeEvery("HORIZON_SCANNING_SET_CHART_COLORS", setHSChartColors);
}

function* setHSSignalsColors(action) {
  yield put(requestSetHSSignalsColorsAction(action.payload));
}

export const setHSSignalsColorsAction = (payload) => ({
  type: "HORIZON_SCANNING_SET_SIGNALS_COLORS",
  payload,
});

export function* watchSetHSSignalsColors() {
  yield takeEvery("HORIZON_SCANNING_SET_SIGNALS_COLORS", setHSSignalsColors);
}
