import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const HORIZON_SCANNING_TOPIC_ARTICLES = createActionTypesOf(
  "HORIZON_SCANNING_TOPIC_ARTICLES"
);

export const HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES = createActionTypesOf(
  "HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES"
);

const requestHSTopicArticlesDataAction = () => ({
  type: HORIZON_SCANNING_TOPIC_ARTICLES.REQUEST,
});

const requestHSTopicArticlesDataSuccessAction = (data) => ({
  type: HORIZON_SCANNING_TOPIC_ARTICLES.SUCCESS,
  payload: data,
});

const requestHSTopicArticlesDataErrorAction = (error) => ({
  type: HORIZON_SCANNING_TOPIC_ARTICLES.ERROR,
  payload: error,
});

function* getHSTopicArticlesData(action) {
  const {
    dateRangeTitle,
    territories,
    authorities,
    topicId,
    sortBy,
    isUnread,
  } = action.payload;

  try {
    yield put(requestHSTopicArticlesDataAction());
    const response = yield call(() => {
      const payload = {
        filters: {
          territories,
          timeframe: dateRangeTitle,
          // sortBy,
          // isUnread,
        },
        id: topicId,
      };
      return Service.postSync(
        "gw",
        `${config.apiPath.gw.getHSTopicArticles}`,
        payload
      );
    });
    yield put(requestHSTopicArticlesDataSuccessAction(response.data));
  } catch (error) {
    yield put(requestHSTopicArticlesDataErrorAction(error));
  }
}

export const getHSTopicArticlesDataAction = (payload) => ({
  type: "HORIZON_SCANNING_TOPIC_ARTICLES",
  payload,
});

export function* watchGetHSTopicArticlesData() {
  yield takeEvery("HORIZON_SCANNING_TOPIC_ARTICLES", getHSTopicArticlesData);
}

const requestClearHSTopicArticlesDataAction = () => ({
  type: HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES.REQUEST,
});

function* clearHSTopicArticlesData() {
  yield put(requestClearHSTopicArticlesDataAction());
}

export const getClearHSTopicArticlesDataAction = () => ({
  type: "HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES",
});

export function* watchClearHSTopicArticlesData() {
  yield takeEvery(
    "HORIZON_SCANNING_CLEAR_TOPIC_ARTICLES",
    clearHSTopicArticlesData
  );
}
