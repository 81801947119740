import { delay, put, takeEvery } from "redux-saga/effects";

import { DialogConfirmationBox } from "../constants";
import { createAction } from "../utils/helpers";

type HandleNotification = {
  title: string;
  message: string;
  confirmationBox: DialogConfirmationBox;
  delay?: number;
};

type OpenNotification = {
  title: string;
  message: string;
  confirmationBox: string;
};

const REDUCER = "NOTIFICATION";
const DEFAULT_DELAY = 2000;

export const OPEN_DIALOG = createAction(REDUCER, "OPEN_DIALOG");
export const CLOSE_DIALOG = createAction(REDUCER, "CLOSE_DIALOG");
const HANDLE_NOTIFICATION = createAction(REDUCER, "HANDLE_NOTIFICATION");

const openNotificationAction = (payload: OpenNotification) => ({
  type: OPEN_DIALOG,
  payload,
});

const closeNotificationAction = () => ({
  type: CLOSE_DIALOG,
});

const handleNotification = (payload: HandleNotification) => ({
  type: HANDLE_NOTIFICATION,
  payload,
});

function* handleNotificationWorker({
  payload,
}: ReturnType<typeof handleNotification>) {
  try {
    const { delay: delayMls, ...data } = payload;
    yield put(openNotificationAction(data));

    yield delay(delayMls || DEFAULT_DELAY);

    yield put(closeNotificationAction());
  } catch (error) {
    console.error(error);
  }
}

export function* watchNotification() {
  yield takeEvery(HANDLE_NOTIFICATION, handleNotificationWorker);
}
