import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const HS_CHARTS_FILTER_METADATA = createActionTypesOf(
  "HS_CHARTS_FILTER_METADATA"
);

const requestChartsFilterMetadataAction = () => ({
  type: HS_CHARTS_FILTER_METADATA.REQUEST,
});

const requestChartsFilterMetadataSuccessAction = (data) => ({
  type: HS_CHARTS_FILTER_METADATA.SUCCESS,
  payload: data,
});

const requestChartsFilterMetadataErrorAction = (error) => ({
  type: HS_CHARTS_FILTER_METADATA.ERROR,
  payload: error,
});

export const getHSChartsFilterMetadataAction = () => ({
  type: "HS_CHARTS_FILTER_METADATA",
});

function* getChartsFilterMetadata() {
  try {
    yield put(requestChartsFilterMetadataAction());
    const response = yield call(() =>
      Service.postSync(
        "gw",
        `${config.apiPath.gw.getHSChartsFilterMetadata}`,
        {}
      )
    );
    yield put(requestChartsFilterMetadataSuccessAction(response.data));
  } catch (error) {
    yield put(requestChartsFilterMetadataErrorAction(error));
  }
}

export function* watchGetHSChartsFilterMetadata() {
  yield takeEvery("HS_CHARTS_FILTER_METADATA", getChartsFilterMetadata);
}
