import isArray from "lodash/isArray";

import {
  HORIZON_SCANNING_SET_TOPIC_FILTER,
  HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY,
  HORIZON_SCANNING_TOPIC_FILTER_STATE,
} from "../actions/horizonScanningTopicFilterActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataStateTopicFilterReady: DATA_STATES.NOT_REQUESTED,
  dataStateTopicFilter: DATA_STATES.NOT_REQUESTED,
  dateRangeFromFilter: null,
  dateRangeToFilter: null,
  dateRangeTitleFilter: null,
  regionsFilter: null,
  authoritiesFilter: null,
  sortByArticles: null,
  readArticles: false,
};

const horizonScanningTopicFilterReducer = createReducer(initialState, {
  [HORIZON_SCANNING_SET_TOPIC_FILTER.REQUEST]: (
    state,
    { payload: { key, value } }
  ) => {
    let nextState = {
      ...state,
      dataStateTopicFilter: DATA_STATES.REQUEST,
    };
    isArray(key) && isArray(value)
      ? key.map((keyItem, index) => {
          nextState = {
            ...nextState,
            [keyItem]: value[index],
          };
        })
      : (nextState = {
          ...nextState,
          [key]: value,
        });

    return {
      ...state,
      ...nextState,
    };
  },
  [HORIZON_SCANNING_SET_TOPIC_FILTER.SUCCESS]: (state) => ({
    ...state,
    dataStateTopicFilter: DATA_STATES.READY,
  }),
  [HORIZON_SCANNING_TOPIC_FILTER_STATE.REQUEST]: (state) => ({
    ...state,
    dataStateTopicFilterReady: DATA_STATES.READY,
  }),
  [HORIZON_SCANNING_TOPIC_CANCEL_FILTER_READY.REQUEST]: (state) => ({
    ...state,
    ...initialState,
  }),
});

export default horizonScanningTopicFilterReducer;
