import { call, put, takeEvery } from "redux-saga/effects";

import { config } from "../config";
import Service from "../services/middleware/service";
import { createActionTypesOf } from "../utils/helpers";

export const HS_TOPIC_FILTER_METADATA = createActionTypesOf(
  "HS_TOPIC_FILTER_METADATA"
);

const requestTopicFilterMetadataAction = () => ({
  type: HS_TOPIC_FILTER_METADATA.REQUEST,
});

const requestTopicFilterMetadataSuccessAction = (data) => ({
  type: HS_TOPIC_FILTER_METADATA.SUCCESS,
  payload: data,
});

const requestTopicFilterMetadataErrorAction = (error) => ({
  type: HS_TOPIC_FILTER_METADATA.ERROR,
  payload: error,
});

export const getHSTopicFilterMetadataAction = (payload) => ({
  type: "HS_TOPIC_FILTER_METADATA",
  payload,
});

function* getTopicFilterMetadata(action) {
  try {
    yield put(requestTopicFilterMetadataAction());
    const response = yield call(() =>
      Service.getSync(
        "gw",
        `${config.apiPath.gw.getHSTopicFilterMetadata}?signalId=${action.payload.hsTopicId}`,
        {}
      )
    );
    yield put(requestTopicFilterMetadataSuccessAction(response.data));
  } catch (error) {
    yield put(requestTopicFilterMetadataErrorAction(error));
  }
}

export function* watchGetHSTopicFilterMetadata() {
  yield takeEvery("HS_TOPIC_FILTER_METADATA", getTopicFilterMetadata);
}
