import { createTheme } from "@mui/material/styles";

import { borderTypes } from "./borderTypes";
import { colors } from "./colors";
import { components } from "./components";
import { colors as legacyColors } from "./legacyColors";
import { textStyles, typography } from "./typography";

/**
 * New Harmony Theme
 * Remove the old theme when the new theme is ready
 */
export const theme = createTheme({
  custom: {
    colors: { ...legacyColors, ...colors },
    text: textStyles,
    borderTypes,
  },
  palette: {
    // Do not manually override the palette, use the custom colors object instead
    text: {
      primary: colors.neutrals.grey[80],
      secondary: colors.neutrals.grey[60],
      disabled: colors.neutrals.grey[40],
      states: {
        hover: "rgba(10, 35, 66, 0.04)",
        selected: "rgba(10, 35, 66, 0.08)",
        focus: "rgba(10, 35, 66, 0.12)",
        focusVisible: "rgba(10, 35, 66, 0.30)",
        outlinedBorder: "rgba(17, 59, 111, 0.90)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    primary: {
      main: colors.brand.oxfordBlue[70],
      dark: colors.brand.oxfordBlue[80],
      light: colors.brand.oxfordBlue[60],
      contrastText: colors.neutrals.white,
      states: {
        hover: "rgba(56, 131, 225, 0.04)",
        selected: "rgba(56, 131, 225, 0.08)",
        focus: "rgba(56, 131, 225, 0.12)",
        focusVisible: "rgba(56, 131, 225, 0.3)",
        outlinedBorder: "rgba(23, 82, 155, 0.9)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    secondary: {
      main: colors.brand.crayolaPink[40],
      dark: colors.brand.crayolaPink[50],
      light: colors.brand.crayolaPink[30],
      contrastText: colors.neutrals.white,
      states: {
        hover: "rgba(237, 77, 101, 0.04)",
        selected: "rgba(237, 77, 101, 0.08)",
        focus: "rgba(237, 77, 101, 0.12)",
        focusVisible: "rgba(237, 77, 101, 0.30)",
        outlinedBorder: "rgba(237, 77, 101, 0.50)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    action: {
      active: colors.brand.lavender[60],
      hover: "rgba(50, 54, 77, 0.08)",
      selected: "rgba(50, 54, 77, 0.15)",
      focus: "rgba(50, 54, 77, 0.25)",
      disabled: "rgba(50, 54, 77, 0.30)",
      disabledBackground: "rgba(50, 54, 77, 0.12)",
    },

    // Do not manually override the palette, use the custom colors object instead
    error: {
      main: colors.semantic.error[30],
      dark: colors.semantic.error[40],
      light: colors.semantic.error[20],
      contrastText: colors.neutrals.white,
      states: {
        hover: "rgba(186, 42, 32, 0.04)",
        selected: "rgba(186, 42, 32, 0.08);",
        focusVisible: "rgba(186, 42, 32, 0.3)",
        outlinedBorder: "rgba(186, 42, 32, 0.5)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    warning: {
      main: colors.semantic.warning[30],
      dark: colors.semantic.warning[40],
      light: colors.semantic.warning[10],
      contrastText: colors.neutrals.white,
      states: {
        hover: "rgba(240, 110, 12, 0.04)",
        selected: "rgba(240, 110, 12, 0.08)",
        focusVisible: "rgba(240, 110, 12, 0.3)",
        outlinedBorder: "rgba(240, 110, 12, 0.5)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    info: {
      main: colors.semantic.info[30],
      dark: colors.semantic.info[40],
      light: colors.semantic.info[20],
      contrastText: colors.neutrals.white,
      states: {
        hover: "rgba(19, 112, 212, 0.04)",
        selected: "rgba(19, 112, 212, 0.08)",
        focusVisible: "rgba(19, 112, 212, 0.3)",
        outlinedBorder: "rgba(19, 112, 212, 0.5)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    success: {
      main: colors.semantic.success[30],
      dark: colors.semantic.success[40],
      light: colors.semantic.success[20],
      contrastText: colors.neutrals.white,
      states: {
        hover: "rgba(34, 133, 67, 0.04)",
        selected: "rgba(34, 133, 67, 0.08)",
        focusVisible: "rgba(34, 133, 67, 0.3)",
        outlinedBorder: "rgba(34, 133, 67, 0.5)",
      },
    },

    // Do not manually override the palette, use the custom colors object instead
    common: {
      black: colors.neutrals.black,
      white: colors.neutrals.white,
    },

    // Do not manually override the palette, use the custom colors object instead
    background: {
      default: colors.neutrals.white,
      paper: colors.neutrals.white,
    },

    // Do not manually override the palette, use the custom colors object instead
    divider: colors.neutrals.grey[30],

    // Do not manually override the palette, use the custom colors object instead
    grey: {
      50: colors.neutrals.grey[10],
      100: colors.neutrals.grey[20],
      200: "rgba(238, 238, 238, 1.00)", // #eeeeee
      300: colors.neutrals.grey[30],
      400: colors.neutrals.grey[40],
      500: colors.neutrals.grey[50],
      600: colors.neutrals.grey[60],
      700: "rgba(85, 90, 97, 1.00)", // #555a61
      800: colors.neutrals.grey[70],
      900: colors.neutrals.grey[80],
      A100: "rgba(245, 245, 245, 1.00)", // #f5f5f5
      A200: "rgba(238, 238, 238, 1.00)", // #eeeeee
      A400: colors.neutrals.grey[40],
      A700: "rgba(85, 90, 97, 1.00)", // #555a61
    },
  },
  // Do not manually override the palette, use the custom colors object instead
  shape: {
    borderRadius: 8,
  },
  spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
  typography,
  components,
});
