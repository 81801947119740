// Font's licence here

import { css } from "@emotion/react";

import InterBold from "@assets/fonts/inter-bold.woff";
import InterBold2 from "@assets/fonts/inter-bold.woff2";
import InterRegular from "@assets/fonts/inter-regular.woff";
import InterRegular2 from "@assets/fonts/inter-regular.woff2";
import InterSemiBold from "@assets/fonts/inter-semi-bold.woff";
import InterSemiBold2 from "@assets/fonts/inter-semi-bold.woff2";

export enum Font {
  Inter = "Inter",
}

export const fontImports = css`
  @font-face {
    font-family: ${Font.Inter};
    font-weight: 600;
    src: url("${InterSemiBold2}") format("woff2"),
      url("${InterSemiBold}") format("woff");
  }

  @font-face {
    font-family: ${Font.Inter};
    font-weight: 700;
    src: url("${InterBold2}") format("woff2"),
      url("${InterBold}") format("woff");
  }

  @font-face {
    font-family: ${Font.Inter};
    font-weight: 400;
    src: url("${InterRegular2}") format("woff2"),
      url("${InterRegular}") format("woff");
  }
`;
