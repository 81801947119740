import { HS_TOPIC_FILTER_METADATA } from "../actions/getHSTopicFilterMetadataActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataState: DATA_STATES.NOT_REQUESTED,
  data: [],
  error: [],
};

const getHSTopicFilterMetadataReducer = createReducer(initialState, {
  [HS_TOPIC_FILTER_METADATA.REQUEST]: (state) => ({
    ...state,
    dataState: DATA_STATES.REQUEST,
  }),
  [HS_TOPIC_FILTER_METADATA.SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    dataState: DATA_STATES.READY,
    data,
  }),
  [HS_TOPIC_FILTER_METADATA.ERROR]: (state, { payload: { error } }) => ({
    ...state,
    dataState: DATA_STATES.FAILURE,
    error,
  }),
});

export default getHSTopicFilterMetadataReducer;
