/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";

import getAuthoritiesListReducer from "./reducers/getAuthoritiesReducer";
import getHSChartsFilterMetadataReducer from "./reducers/getHSChartsFilterMetadataReducer";
import getHSTopicFilterMetadataReducer from "./reducers/getHSTopicFilterMetadataReducer";
import getTerritoriesListReducer from "./reducers/getTerritoriesReducer";
import horizonScanningChartColorsReducer from "./reducers/horizonScanningChartColorsReducer";
import horizonScanningReducer from "./reducers/horizonScanningReducer";
import horizonScanningTopicArticlesReducer from "./reducers/horizonScanningTopicArticlesReducer";
import horizonScanningTopicFilterReducer from "./reducers/horizonScanningTopicFilterReducer";
import horizonScanningTopicInsightsReducer from "./reducers/horizonScanningTopicInsightsReducer";
import notificationReducer from "./reducers/notificationReducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    notification: notificationReducer,
    territoriesList: getTerritoriesListReducer,
    authoritiesList: getAuthoritiesListReducer,

    horizonScanning: horizonScanningReducer,
    hsChartsFilterMetadata: getHSChartsFilterMetadataReducer,
    horizonScanningChartColors: horizonScanningChartColorsReducer,

    horizonScanningTopicFilter: horizonScanningTopicFilterReducer,
    horizonScanningTopicInsights: horizonScanningTopicInsightsReducer,
    horizonScanningTopicArticles: horizonScanningTopicArticlesReducer,
    hsTopicFilterMetadata: getHSTopicFilterMetadataReducer,

    ...injectedReducers,
  });
}
