import { ReactElement } from "react";
import format from "date-fns/format";

type Props = {
  children: ReactElement;
};

const AppVersion = ({ children }: Props) => {
  console.log(CUSTOM_VERSION, " BUILD NUMBER");
  console.log(format(new Date(CUSTOM_DATE), "PPpp"), " BUILD DATE");

  return children;
};

export default AppVersion;
