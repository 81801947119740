import { AUTHORITIES_LIST } from "../actions/getAuthoritiesActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataState: DATA_STATES.NOT_REQUESTED,
  data: [],
  error: [],
};

const getAuthoritiesListReducer = createReducer(initialState, {
  [AUTHORITIES_LIST.REQUEST]: (state) => ({
    ...state,
    dataState: DATA_STATES.REQUEST,
  }),
  [AUTHORITIES_LIST.SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    dataState: DATA_STATES.READY,
    data,
  }),
  [AUTHORITIES_LIST.ERROR]: (state, { payload: { error } }) => ({
    ...state,
    dataState: DATA_STATES.FAILURE,
    error,
  }),
});

export default getAuthoritiesListReducer;
