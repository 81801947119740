import isArray from "lodash/isArray";

import {
  HORIZON_SCANNING,
  HORIZON_SCANNING_FILTER,
  HORIZON_SCANNING_FILTER_STATE,
  HORIZON_SCANNING_SET_TOPIC,
} from "../actions/horizonScanningActions";
import { DATA_STATES } from "../utils/entities";
import { createReducer } from "../utils/helpers";

const initialState = {
  dataStateChartsFilterReady: DATA_STATES.NOT_REQUESTED,
  dataStateChartsFilter: DATA_STATES.NOT_REQUESTED,
  dateRangeFromFilter: null,
  dateRangeToFilter: null,
  dateRangeTitleFilter: null,
  regionsFilter: null,
  authoritiesFilter: null,

  dataStateChoosedTopics: DATA_STATES.READY,
  choosedTopics: [],

  dataStateChartsData: DATA_STATES.NOT_REQUESTED,
  chartsData: [],
  errorChartsData: null,
};

const horizonScanningReducer = createReducer(initialState, {
  [HORIZON_SCANNING_FILTER.REQUEST]: (state, { payload: { key, value } }) => {
    let nextState = {
      ...state,
      dataStateChartsFilter: DATA_STATES.REQUEST,
    };
    isArray(key) && isArray(value)
      ? key.map((keyItem, index) => {
          nextState = {
            ...nextState,
            [keyItem]: value[index],
          };
        })
      : (nextState = {
          ...nextState,
          [key]: value,
        });

    return {
      ...state,
      ...nextState,
    };
  },
  [HORIZON_SCANNING_FILTER.SUCCESS]: (state) => ({
    ...state,
    dataStateChartsFilter: DATA_STATES.READY,
  }),
  [HORIZON_SCANNING.REQUEST]: (state) => ({
    ...state,
    dataStateChartsData: DATA_STATES.REQUEST,
  }),
  [HORIZON_SCANNING.SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    dataStateChartsData: DATA_STATES.READY,
    dataStateChartsFilter: DATA_STATES.READY,
    chartsData: data,
  }),
  [HORIZON_SCANNING_SET_TOPIC.REQUEST]: (state, { payload: { data } }) => ({
    ...state,
    choosedTopics: data,
    dataStateChoosedTopics: DATA_STATES.REQUEST,
  }),
  [HORIZON_SCANNING_SET_TOPIC.SUCCESS]: (state) => ({
    ...state,
    dataStateChoosedTopics: DATA_STATES.READY,
  }),
  [HORIZON_SCANNING.ERROR]: (state, { payload: { error } }) => ({
    ...state,
    dataStateChartsData: DATA_STATES.FAILURE,
    dataStateChartsFilter: DATA_STATES.FAILURE,
    errorChartsData: error,
  }),
  [HORIZON_SCANNING_FILTER_STATE.REQUEST]: (state) => ({
    ...state,
    dataStateChartsFilterReady: DATA_STATES.READY,
  }),
});

export default horizonScanningReducer;
