/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "@babel/polyfill";
import "sanitize.css/sanitize.css";

import { theme } from "@app/styles";
import { ThemeProvider } from "@material-ui/styles";
// Import i18n messages
import LinearProgress from "@mui/material/LinearProgress";
// Import react-query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import Launch Darkly
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
// Import all the third party stuff
import { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { config } from "./config";
import configureStore from "./configureStore";
// Import root app
import * as serviceWorker from "./serviceWorker";
import history from "./utils/history";
import AppVersion from "./v2/app/components/AppVersion";
import { lazyWithRetry } from "./v2/app/utils/lazy";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Create redux store with history
const initialState = {};
export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("app");
export const dashboardStore = {
  home: {
    count: 0,
    search: {},
    filters: {
      territories: [],
      industries: [],
      alertTypes: [],
      company: [],
      time: ["Last 30 Days"],
      isFilterActive: false,
    },
  },
  alerts: {
    count: 0,
    search: {},
    filters: {
      territories: [],
      industries: [],
      alertTypes: [],
      company: [],
      time: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  candidateAlerts: {
    count: 0,
    search: {},
    filters: {
      territories: [],
      industries: [],
      candidateAlertsTypes: [],
      company: [],
      time: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  documents: {
    count: 0,
    search: {},
    filters: {
      territories: [],
      industries: [],
      docType: [],
      tags: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  guidebooks: {
    count: 0,
    search: {},
    filters: {
      territories: [],
      industries: [],
      topics: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  videos: {
    count: 0,
    search: {},
    filters: {
      territories: [],
      industries: [],
      topics: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  publishers: {
    count: 0,
    search: {},
    filters: {
      keywords: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  feeds: {
    count: 0,
    search: {},
    filters: {
      publisher: "",
      keywords: [],
      isFilterActive: false,
      forStateChange: false,
    },
  },
  openPopup: "",
  navigationHistory: [],
  redirectionUrl: null,
};

const Main = lazyWithRetry(() => import("./views/containers/App/Main"));

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(["./i18n", "views/containers/App/MainContainer"], () => {
//     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     render(translationMessages);
//     serviceWorker.unregister();
//   });
// }

(async () => {
  serviceWorker.unregister();
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_ID,
    context: {
      key: "user",
      name: "name",
      anonymous: true,
    },
    options: {
      streaming: process.env.NODE_ENV !== "development",
    },
  });

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <LDProvider>
        <AppVersion>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <Suspense fallback={<LinearProgress />}>
                <Main />
              </Suspense>
            </ThemeProvider>
          </Provider>
        </AppVersion>
      </LDProvider>
    </QueryClientProvider>,
    MOUNT_NODE
  );
})();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (config.nodeEnv === "production") {
  serviceWorker.unregister();
}
