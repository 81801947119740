import { CLOSE_DIALOG, OPEN_DIALOG } from "../actions/notificationActions";
import { createReducer } from "../utils/helpers";

const initialState = {
  message: "",
  title: "",
  confirmationBox: null,
  open: false,
};

const notificationReducer = createReducer(initialState, {
  [OPEN_DIALOG]: (state, { payload }) => ({
    ...state,
    ...payload,
    open: true,
  }),
  [CLOSE_DIALOG]: () => ({
    ...initialState,
  }),
});

export default notificationReducer;
