export const colors = {
  charts: [
    "#003175",
    "#0655C4",
    "#449FF2",
    "#FF5B7E",
    "#8C9DF4",
    "#4951EC",
    "#039DAE",
    "#50BFA5",
    "#FFC38D",
    "#F99746",
    "#BD3B27",
  ],
  data: [
    "#003175",
    "#0655C4",
    "#449FF2",
    "#C7D8FF",
    "#8C9DF4",
    "#4951EC",
    "#039DAE",
    "#50BFA5",
    "#FFC38D",
    "#F99746",
    "#BD3B27",
    "#FF5B7E",
    "#FFDEEB",
  ],
};
