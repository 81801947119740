import isNull from "lodash/isNull";
import ReactHtmlParser from "react-html-parser";
import Cookies from "universal-cookie";

import { config } from "../config";

const cookies = new Cookies();

export function setCookieForAPP(name, value) {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  cookies.set(name, value, { path: "/", expires: d });
}

export function getCookieForAPP(name) {
  return cookies.get(name, { path: "/" });
}

export function getAllCookieForAPP() {
  return cookies.getAll({ path: "/" });
}

export function getApiGatewayUrl() {
  return config.apiGateway;
}

export function getUploadedGatewayUrl() {
  return `${config.apiGateway}/uploads/`;
}

// Decode the data
export function htmlDataDecode(decodedString) {
  try {
    return ReactHtmlParser(decodeURIComponent(decodedString));
  } catch (e) {
    return ReactHtmlParser(decodedString);
  }
}

export function getSourceUrlAfterValidation(alertSourceUrl) {
  if (alertSourceUrl !== "null" && alertSourceUrl) {
    if (
      !/^((http|https|ftp):\/\/)/.test(alertSourceUrl) &&
      alertSourceUrl.includes(".")
    ) {
      return `http://${alertSourceUrl}`;
    }
    return alertSourceUrl;
  }
  return "";
}

function createSetState(error, errorMsg, errorObj) {
  const setState = {};
  for (let i = 0; i < error.length; i++) {
    setState[error[i]] = false;
  }
  for (let i = 0; i < errorMsg.length; i++) {
    setState[errorMsg[i]] = "";
  }
  if (
    errorObj &&
    Object.keys(errorObj).length &&
    Object.values(errorObj).length
  ) {
    const errObjKey1 = Object.keys(errorObj)[0];
    const errObjkey2 = Object.keys(errorObj)[1];
    const errObjVal1 = Object.values(errorObj)[0];
    const errObjVal2 = Object.values(errorObj)[1];

    setState[errObjKey1] = errObjVal1;
    setState[errObjkey2] = errObjVal2;
  }
  return setState;
}

function setStateObjCreateUpdateAlert(errorObj) {
  const error = [
    "titleError",
    "industryError",
    "authorityError",
    "RegulationIdError",
    "regulationDocTypeError",
    "sourceError",
    "requestsError",
    "keywordsError",
    "summaryError",
    "territoryError",
    "dateOfDeadlineError",
    "dateOfPublicationError",
    "clientsError",
    "fileError",
  ];

  const errorMsg = [
    "titleErrorMsg",
    "errorMsg",
    "industryErrorMsg",
    "authorityErrorMsg",
    "RegulationIdErrorMsg",
    "regulationDocTypeErrorMsg",
    "sourceErrorMsg",
    "requestsErrorMsg",
    "tagsErrorMsg",
    "keywordsErrorMsg",
    "summaryErrorMsg",
    "territoryErrorMsg",
    "dateOfDeadlineErrorMsg",
    "dateOfPublicationErrorMsg",
    "clientsErrorMsg",
    "fileErrorMsg",
  ];
  return createSetState(error, errorMsg, errorObj);
}

function setStateObjCreateUpdateRequest(errorObj) {
  const error = [
    "industryError",
    "territoryError",
    "clientsError",
    "fileError",
    "requestError",
    "scopeError",
    "requestTitleError",
    "requestStatusError",
    "requestAssignedToError",
    "requestSubTypeError",
  ];

  const errorMsg = [
    "errorMsg",
    "industryErrorMsg",
    "territoryErrorMsg",
    "clientsErrorMsg",
    "fileErrorMsg",
    "requestErrorMsg",
    "scopeErrorMsg",
    "requestTitleErrorMsg",
    "requestStatusErrorMsg",
    "requestAssignedToErrorMsg",
    "requestSubTypeErrorMsg",
  ];
  return createSetState(error, errorMsg, errorObj);
}

export function updateRequestValidation(me) {
  if (me.requestTitleRef.state.value === "") {
    me.setState(
      setStateObjCreateUpdateRequest({
        requestTitleError: true,
        requestTitleErrorMsg: "Please fill request title field.",
      })
    );
    return false;
  }
  if (
    !me.state.status ||
    (me.requestStatus &&
      (me.requestStatus.state.value === null ||
        me.requestStatus.state.value.length <= 0))
  ) {
    me.setState(
      setStateObjCreateUpdateRequest({
        requestStatusError: true,
        requestStatusErrorMsg: "Please select status for request.",
      })
    );
    return false;
  }
  if (
    me.requestIndustriesRef.state.value === null ||
    me.requestIndustriesRef.state.value.length <= 0
  ) {
    me.setState(
      setStateObjCreateUpdateRequest({
        industryError: true,
        industryErrorMsg: "Please select a Industry.",
      })
    );
    return false;
  }
  if (
    me.requestTypeRef.state.value === null ||
    me.requestTypeRef.state.value.length <= 0
  ) {
    me.setState(
      setStateObjCreateUpdateRequest({
        requestSubTypeError: true,
        requestSubTypeErrorMsg: "Please select request type.",
      })
    );
    return false;
  }
  if (
    !(
      me.requestTerritoriesRef?.state?.value?.length ||
      me.organizationsRef?.state?.value?.length
    )
  ) {
    me.setState(
      setStateObjCreateUpdateRequest({
        territoryError: true,
        territoryErrorMsg: "Please select a territory or organization",
      })
    );
    return false;
  }
  if (this.requestScopeRef && this.requestScopeRef.state.content === "") {
    me.setState(
      setStateObjCreateUpdateRequest({
        scopeError: true,
        scopeErrorMsg: "Please enter your request scope.",
      })
    );
    return false;
  }
  if (
    me.requestAssignedToRef.state.value === null ||
    me.requestAssignedToRef.state.value.length <= 0
  ) {
    me.setState(
      setStateObjCreateUpdateRequest({
        requestAssignedToError: true,
        requestAssignedToErrorMsg: "Please select assignee.",
      })
    );
    return false;
  }
  me.setState(setStateObjCreateUpdateRequest({}));
  return true;
}
