import { ThemeOptions, TypographyVariantsOptions } from "@mui/material";

import { Font } from "./fonts";

const rootFontSize = 16;

const fontFamily = {
  primary: Font.Inter,
};

const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const textStyles = {
  lineHeight: {
    xSmall: "0.75rem",
    small: "1rem",
    medium: "1.125rem",
    large: "1.5rem",
    xLarge: "1.75rem",
    "2xLarge": "3rem",
  },
  sizes: {
    xSmall: "0.625rem",
    small: "0.75rem",
    medium: "0.875rem",
    large: "1rem",
    xLarge: "1.25rem",
    "2xLarge": "2.25rem",
  },
  // Remove when legacy components are removed
  fontFamily,
  fontWeights,
};

export const typographyStyles: TypographyVariantsOptions = {
  /* Bold display headings */
  headingD1: {
    fontWeight: fontWeights.bold,
    fontSize: "108px",
    lineHeight: "130px",
    letterSpacing: "-2px",
  },
  headingD2: {
    fontWeight: fontWeights.bold,
    fontSize: "78px",
    lineHeight: "94px",
    letterSpacing: "-1px",
  },
  headingD3: {
    fontWeight: fontWeights.bold,
    fontSize: "66px",
    lineHeight: "79px",
    letterSpacing: "-1px",
  },
  headingD4: {
    fontWeight: fontWeights.bold,
    fontSize: "54px",
    lineHeight: "65px",
    letterSpacing: "-0.5px",
  },
  headingD5: {
    fontWeight: fontWeights.bold,
    fontSize: "48px",
    lineHeight: "58px",
    letterSpacing: "-0.5px",
  },
  headingD6: {
    fontWeight: fontWeights.bold,
    fontSize: "42px",
    lineHeight: "50px",
    letterSpacing: "-0.5px",
  },
  headingD7: {
    fontWeight: fontWeights.bold,
    fontSize: "36px",
    lineHeight: "43px",
  },
  headingD8: {
    fontWeight: fontWeights.bold,
    fontSize: "30px",
    lineHeight: "36px",
  },
  headingD9: {
    fontWeight: fontWeights.bold,
    fontSize: "24px",
    lineHeight: "31px",
  },
  /* S-R Headings (semi-bold and regular) */
  heading1: {
    fontWeight: fontWeights.semiBold,
    fontSize: "44px",
    lineHeight: "55px",
  },
  heading2: {
    fontWeight: fontWeights.semiBold,
    fontSize: "36px",
    lineHeight: "45px",
  },
  heading3: {
    fontWeight: fontWeights.semiBold,
    fontSize: "30px",
    lineHeight: "37.5px",
  },
  heading3r: {
    fontWeight: fontWeights.regular,
    fontSize: "30px",
    lineHeight: "37.5px",
  },
  heading4: {
    fontWeight: fontWeights.semiBold,
    fontSize: "24px",
    lineHeight: "31.2px",
  },
  heading5: {
    fontWeight: fontWeights.semiBold,
    fontSize: "20px",
    lineHeight: "26px",
  },
  heading5r: {
    fontWeight: fontWeights.regular,
    fontSize: "20px",
    lineHeight: "26px",
  },
  /* Body/body-18 */
  body18Regular: {
    fontWeight: fontWeights.regular,
    fontSize: "18px",
    lineHeight: "27.9px",
  },
  body18RegularUnderline: {
    fontWeight: fontWeights.regular,
    fontSize: "18px",
    lineHeight: "27.9px",
    textDecoration: "underline",
  },
  body18Semi: {
    fontWeight: fontWeights.semiBold,
    fontSize: "18px",
    lineHeight: "27.9px",
  },
  body18Bold: {
    fontWeight: fontWeights.bold,
    fontSize: "18px",
    lineHeight: "27.9px",
  },
  /* Body/body-16 */
  body16Regular: {
    fontWeight: fontWeights.regular,
    fontSize: "16px",
    lineHeight: "24.8px",
  },
  body16RegularUnderline: {
    fontWeight: fontWeights.regular,
    fontSize: "16px",
    lineHeight: "24.8px",
    textDecoration: "underline",
  },
  body16Semi: {
    fontWeight: fontWeights.semiBold,
    fontSize: "16px",
    lineHeight: "24.8px",
  },
  body16SemiUnderline: {
    fontWeight: fontWeights.semiBold,
    fontSize: "16px",
    lineHeight: "24.8px",
    textDecoration: "underline",
  },
  body16Bold: {
    fontWeight: fontWeights.bold,
    fontSize: "16px",
    lineHeight: "24.8px",
  },
  /* Body/body-14 */
  body14Regular: {
    fontWeight: fontWeights.regular,
    fontSize: "14px",
    lineHeight: "21.7px",
  },
  body14RegularUnderline: {
    fontWeight: fontWeights.regular,
    fontSize: "14px",
    lineHeight: "21.7px",
    textDecoration: "underline",
  },
  body14Semi: {
    fontWeight: fontWeights.semiBold,
    fontSize: "14px",
    lineHeight: "21.7px",
  },
  body14SemiUnderline: {
    fontWeight: fontWeights.semiBold,
    fontSize: "14px",
    lineHeight: "21.7px",
    textDecoration: "underline",
  },
  body14Bold: {
    fontWeight: fontWeights.bold,
    fontSize: "14px",
    lineHeight: "21.7px",
  },
  /* Body/body-12 */
  body12Regular: {
    fontWeight: fontWeights.regular,
    fontSize: "12px",
    lineHeight: "18.6px",
  },
  body12RegularUnderline: {
    fontWeight: fontWeights.regular,
    fontSize: "12px",
    lineHeight: "18.6px",
    textDecoration: "underline",
  },
  body12Semi: {
    fontWeight: fontWeights.semiBold,
    fontSize: "12px",
    lineHeight: "18.6px",
  },
  body12Bold: {
    fontWeight: fontWeights.bold,
    fontSize: "12px",
    lineHeight: "18.6px",
  },
  /* Body/special */
  specialButton14: {
    fontWeight: fontWeights.semiBold,
    fontSize: "14px",
    lineHeight: "16px",
  },
  specialButton16: {
    fontWeight: fontWeights.semiBold,
    fontSize: "16px",
    lineHeight: "18px",
  },
  specialButton18: {
    fontWeight: fontWeights.semiBold,
    fontSize: "18px",
    lineHeight: "20px",
  },
  specialCapsRegular: {
    fontWeight: fontWeights.regular,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.16px",
    textTransform: "uppercase",
  },
  specialCapsSemi: {
    fontWeight: fontWeights.semiBold,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.16px",
    textTransform: "uppercase",
  },
  specialCapsBold: {
    fontWeight: fontWeights.bold,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.16px",
    textTransform: "uppercase",
  },
  specialSmall: {
    fontWeight: fontWeights.medium,
    fontSize: "10px",
    lineHeight: "13px",
  },
  specialXSmall: {
    fontWeight: fontWeights.medium,
    fontSize: "8px",
    lineHeight: "10px",
  },
};

export const typography: ThemeOptions["typography"] = {
  fontFamily: fontFamily.primary,
  fontSize: rootFontSize,
  fontWeightRegular: fontWeights.regular,
  fontWeightMedium: fontWeights.semiBold,
  fontWeightBold: fontWeights.bold,
  htmlFontSize: rootFontSize,

  ...typographyStyles,
};
